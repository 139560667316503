<template>
  <div>
    <slot></slot>
  </div>
</template>

<style scoped>
div {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  max-width: 750px;
  color: white;
}
</style>
